<template>
	<div class="card-outer" @click="viewTour()">
		<div class="name">{{ name }}</div>		
		<div class="image" :style="{'background-image': 'url(' + image + ')'}">
			<div class="location">
				<img class="location-icon" src="@/assets/tours/location-icon.png">
				<span class="start">{{ startLocation }}</span>
				<span class="end" v-if="endLocation && endLocation != startLocation"> - {{ endLocation }}</span>
			</div>	
		</div>		
		<div class="description">{{ description }}</div>
		<div class="length">
			<img src="@/assets/tours/day-icon.png" alt="Sunset icon"/>
			<span v-if="length == 1">
				Day trip
			</span>
			<span v-else>
				{{ length }} days
			</span>
		</div>
		<div class="price">
			<span class="starting">Starting from</span>
			<span class="amount">
				{{ currencySymbol(fromPrice.currency) }}{{ fromPrice.amount.substr(0, fromPrice.amount.indexOf('.')) }}<span class="small">{{ fromPrice.amount.substr(fromPrice.amount.indexOf('.'), fromPrice.amount.length) }}</span>
			</span>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';

    export default {
        name: 'ToursOption',
        props: {
			id: {
				default: null,
			},
			name: {
				default: null,
			},
			images: {
				default: null,
			},
			startLocation: {
				default: null,
			},
			endLocation: {
				default: null,
			},
			length: {
				default: null,
			},
			description: {
				default: null,
			},
			fromPrice: {
				default: () => {
					return {
						amount: "00.00",
						currency: "GBP",
					}
				},
			},
		},    
		data() {
			return {
				// showReturnOpts: false,
				// returnKey: false,
				image: "",
			}
		},    
        methods: {
			currencySymbol: (currencyCode) => helpers.currencySymbol(currencyCode),
			viewTour() {
				if(this.id) {
					router.push({ 
						name: 'Tour Details', 
						params: { 
								tour_id: this.id,
							}
						}
					);
				}
			}
		},
		computed: {
			
		},
		created() {
			let imagesPriority = [
				"BANNER_HIRES", // This is actually highest quality, but it's excessive for our purposes. Still better than regular 'BANNER' though.
				"BANNER_DESKTOP",
				"BANNER", // 
				"LARGE_SQUARE" // Highest priority
			];
			// Order images based on priority
			if(this.images && this.images.length > 0) {
				let orderedImages = this.images.sort((a, b) => {
					// Order based on imagesPriority
					let aIndex = imagesPriority.indexOf(a.type);
					let bIndex = imagesPriority.indexOf(b.type);
					if(aIndex > bIndex) {
						return -1;
					}
					if(aIndex < bIndex) {
						return 1;
					}
					return -1;
				});
				// Set the main image href to the highest priority image
				this.image = orderedImages[0].image_href;
			}
			
		}
    }
</script>

<style scoped>
	.card-outer {
		max-width: 100%;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 10px;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		padding: 11px;
		cursor: pointer;
	}
	.card-outer .name {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		margin-top: 9px;
		margin-bottom: 15px;
	}
	.card-outer .image {
		width: 100%;
		height: 140px;
		color: white;
		position: relative;
		border-radius: 10px;
		margin-bottom: 10px;
		background-size: cover;
		background-position: center;
	}
	.card-outer .image .location {
		position: absolute;
		bottom: 8px;
		left: 6px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 6px 9px;
		color: #50555C;
		font-size: 10px;
		line-height: 10px;
	}
	.card-outer .image .location * {
		vertical-align: middle;
	}
	.card-outer .image .location .location-icon {
		width: 8px;
		margin-right: 5px;
	}
	.card-outer .description {
		font-weight: 400;
		font-size: 10px;
	
		color: #50555C;
		margin-bottom: 10px;

		
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		line-height: 12px;
		max-height: 36px;
		overflow: hidden;
	}
	.card-outer .length {
		display: inline-block;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;

		color: #212121;
		vertical-align: middle;
	}
	.card-outer .length img {
		vertical-align: middle;
		width: 19px;
	}
	.card-outer .length span {
		vertical-align: middle;
	}
	.card-outer .price {
		float: right;
		position: relative;
		font-weight: 400;
		font-size: 8px;
		line-height: 10px;

		text-align: right;

		color: #50555C;
	}
	.card-outer .price .starting {
		display: inline-block;
		width: 40px;
	}
	.card-outer .price .amount {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: right;

		color: #000000;
	}
	.card-outer .price .amount .small {
		font-size: 8px;
	}
</style>
